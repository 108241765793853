import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const typedOperators = {
        string: ["contains", "notcontains", "equals", "notequals", "beginswith", "endswith", "notendswith", "isnotblank", "isblank", "notinlist", "inlist"],
        boolean: ["istrue", "isfalse"],
        bit: ["istrue", "isfalse"],
        number: ["equals", "notequals", "greaterthanorequal", "greaterthan", "lessthanorequal", "lessthan", "isnull", "isnotnull", "inlist", "notinlist", "between", 'notbetween'],
        date: ["dateequals", "dategreaterthan", "dategreaterthanorequal", "datelessthan", "datelessthanorequal", "datebetween", "datenotbetween", "isnull", "isnotnull"],
        datetime: ["dateequals", "dategreaterthan", "dategreaterthanorequal", "datelessthan", "datelessthanorequal", "datebetween", "datenotbetween", "isnull", "isnotnull"],
        uniqueidentifier: ["equals", "notequals", "isnotnull", "isnull", "notinlist", "inlist"]
    };

    const operatorTitles = {
        equals: 'Equals',
        greaterthan: 'Greater than',
        lessthan: 'Less than',
        beginswith: 'Starts with',
        endswith: 'Ends with',
        contains: 'Contains',
        isnull: 'Is Null',
        istrue: 'Is true',
        inlist: 'In list',
        between: 'Between',
        like: 'Contains',
        isblank: 'Is empty',
        notequals: 'Not equals',
        greaterthanorequal: 'Greaterjn than or equal',
        lessthanorequal: 'Less than or equal',
        notbeginswith: 'Not starts with',
        notendswith: 'Not ends with',
        notcontains: 'Not contains',
        isnotnull: 'Is not null',
        isfalse: 'Is false',
        notinlist: 'Not in list',
        notbetween: 'Not between',
        dateequals: 'Equals',
        //  datenotequals: $t('Not equals'), 
        datebetween: 'Between',
        datenotbetween: 'Not between',
        timebetween: 'Time between',
        timeequals: 'Time equals',
        timebefore: 'Time before',
        timeafter: 'Time after',
        notlike: 'Not contains',
        isnotblank: 'Is not empty',
        dategreaterthan: 'Greater than',
        datelessthan: 'Less than ',
        dategreaterthanorequal: 'Greatert than or equal',
        datelessthanorequal: 'Less than or equal',
    };

    const operators = {
        EQUALS: "equals",
        GREATERTHAN: "greaterthan",
        LESSTHAN: "lessthan",
        BEGINSWITH: "beginswith",
        ENDSWITH: "endswith",
        CONTAINS: "contains",
        ISNULL: "isnull",
        ISTRUE: "istrue",
        INLIST: "inlist",
        BETWEEN: "between",
        LIKE: "like",
        ISBLANK: "isblank",
        NOTEQUALS: "notequals",
        GREATERTHANOREQUAL: "greaterthanorequal",
        LESSTHANOREQUAL: "lessthanorequal",
        NOTBEGINSWITH: "notbeginswith",
        NOTENDSWITH: "notendswith",
        NOTCONTAINS: "notcontains",
        ISNOTNULL: "isnotnull",
        ISFALSE: "isfalse",
        NOTINLIST: "notinlist",
        NOTBETWEEN: "notbetween",
        DATEEQUALS: "dateequals",
        TIMEBETWEEN: "timebetween",
        TIMEEQUALS: "timeequals",
        TIMEBEFORE: "timebefore",
        TIMEAFTER: "timeafter",
        NOTLIKE: "notlike",
        NOTDATEQUALS: "notdatequals",
        DATEGREATERTHAN: "dategreaterthan",
        DATEGREATERTHANOREQUAL: "dategreaterthanorequal",
        DATELESSTHAN: "datelessthan",
        DATELESSTHANOREQUAL: "datelessthanorequal",
        DATEBETWEEN: "datebetween",
        DATENOTBETWEEN: "datenotbetween",
        ISNOTBLANK: "isnotblank"
    };

    const valueTypes = {
        NULL: "null",
        STRING: "string",
        NUMBER: "number",
        BOOLEAN: "boolean",
        UNIQUEIDENTIFIER: "uniqueidentifier",
        ARRAY: "array",
        DATETIME: "datetime",
        DATE: "date",
        SPECIAL: "special"
    };

    const operatorDefaults = {
        string: "contains",
        boolean: "istrue",
        bit: "istrue",
        number: "equals",
        date: "datebetween",//"dateequals",
        datetime: "datebetween",//"dateequals",
        uniqueidentifier: "equals"
    };

    self.o365.exportScripts({
        operatorDefaults,
        operatorTitles,
        typedOperators,
        operators,
        valueTypes
    });
})();
